<template>
  <div class="work">
    <div class="make" v-if="ismake">
      <template>
        <div class="login">
          <div class="form">
            <h1 class="title">{{company.name}}</h1>
            <el-form ref="ruleForm" label-width="0" size="medium">
              <el-form-item label prop="username">
                <!-- <el-input placeholder="请输入员工姓名" v-model="staff.name" autocomplete="off"></el-input> -->
                <el-input v-model.trim="staff.name" autocomplete="off" placeholder="请输入员工姓名">
                  <i slot="prefix" class="el-input__icon el-icon-user"></i>
                </el-input>
              </el-form-item>
              <el-form-item label prop="password">
                <!-- <el-input style="margin-top: 20px;" placeholder="请输入员工编号" v-model="staff.code" autocomplete="off"></el-input> -->
                <el-input v-model.trim="staff.code" autocomplete="off" placeholder="请输入员工编号">
                  <i slot="prefix" class="el-input__icon el-icon-lock"></i>
                </el-input>
              </el-form-item>
              <el-form-item>
                <el-button class="submit" type="primary" @click="isstaff()">进入</el-button>
              </el-form-item>
            </el-form>
          </div>
          <!-- <div class="footerLogin">
            盘工-生产管理平台 - <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" rel="noopener noreferrer">闽ICP备18016756号-1 </a>
          </div> -->
        </div>
      </template>
      <!-- <div class="input">

        <el-input placeholder="请输入员工姓名" v-model="staff.name" autocomplete="off"></el-input>
        <el-input style="margin-top: 20px;" placeholder="请输入员工编号" v-model="staff.code" autocomplete="off"></el-input>\
        <el-button @click="isstaff" style="  width: 100%;" type="primary">进入</el-button>
      </div> -->
    </div>
    <div class="title2">
      生产日报表
    </div>
    <div class="sele">
      <span>机器：</span>
      <el-select v-model="iot_id" placeholder="请选择" @change='change_iot_id'>
        <el-option v-for="item in devices" :key="item.iot_id" :label="item.nick_name" :value="item.iot_id">
        </el-option>
      </el-select>
    </div>
    <div class="sele">
      <span>操作员：</span>
      <el-select v-model="staffsid" placeholder="请选择">
        <el-option v-for="item in staffs" :key="item.id" :label="item.name" :value="item.id">
        </el-option>
      </el-select>
    </div>

    <!-- 表格 -->
    <div class="tablebox">
      <el-table :data="schedules" style="width: 100%">
        <!-- <el-table-column prop="date" label="计划时间" min-width="28%">
          <template slot-scope="scope">

            <span>{{dayjs(scope.row.on_time).format('MM-DD HH:mm')}}~
              <br>
              {{dayjs(scope.row.off_time).format('MM-DD HH:mm')}}</span>
          </template>
        </el-table-column> -->
        <el-table-column prop="plan_work_time" label="预计加工时间" min-width="28%">
        </el-table-column>
        <el-table-column prop="material_name" label="名称" min-width="23%">
        </el-table-column>
        <el-table-column prop="num" label="剩余数量" min-width="17%">
          <template slot-scope="scope">

            <span>{{scope.row.num - scope.row.complete_num}}</span>

          </template>
        </el-table-column>
        <el-table-column label="状态" min-width="17%">
          <template slot-scope="scope">

            <span style="color:#67C23A;" v-if="scope.row.status === 2 && device.schedule_id === scope.row.id">进行中</span>
            <span style="color:red;" v-else-if="scope.row.status === 2">未完成</span>
            <span style="color:red;" v-else-if="scope.row.status === 1">未开工</span>

          </template>

        </el-table-column>
        <el-table-column label="操作" min-width="15%">
          <template slot-scope="scope">
            <el-button v-if="device.production_status !== 'on'" @click="xz(scope.row)" type="text">选择</el-button>

          </template>

        </el-table-column>
      </el-table>
    </div>

    <!-- 信息 -->
    <div class="collapsebox">
      <el-collapse v-model="activeNames">
        <el-collapse-item name="1">
          <template slot="title">
            <div class="collapsetitle">
              加工信息
            </div>

          </template>
          <div class="dataP">
            机器状态：<span v-if="device.production_status === 'on'" style="color:#67C23A;">开工</span> <span v-else style="color:red;">待机</span>
          </div>
          <div class="dataP">
            操作员工：<span>{{device.handle_staff}}</span>
          </div>
          <div class="dataP">
            当前加工任务：<span>{{device.curr_material_name}}</span>
          </div>
          <div class="dataP">
            工序：<span>{{device.work_procedure}}</span>
          </div>
          <div class="dataP">
            <div class="num">
              计划数量：<span>{{device.num}}</span>
            </div>
            <div class="num">
              已完成数量：<span>{{device.complete_num}}</span>
            </div>
            <div class="num">
              剩余数量：<span style="color:#F56C6C;">{{device.num ? device.num-device.complete_num:''}}</span>
            </div>

          </div>
          <div class="dataP">
            装夹方式：<span>{{device.clamp_way}}</span>
          </div>
          <div class="dataP">
            备注：<span>{{device.remark}}</span>
          </div>
          <div class="dataP">
            <div class="num" style="width:80%;">
              开始时间：<span>{{startTime}}</span>
            </div>

          </div>
          <div class="dataP">

            <div class="num" style="width:50%;">
              已工作时间：<span style="color:#67C23A;"> {{device.production_status === 'on'?disposeDate(startTime):''}}</span>
            </div>
          </div>

        </el-collapse-item>

      </el-collapse>
    </div>

    <div class="btnbox" v-if="device.production_status !== 'on'">
      <el-button class="btn" @click="on" type="success">开工</el-button>
      <el-button class="btn" @click="opInsert" type="warning">插单</el-button>
      <el-button class="btn" style="width:calc(80% + 10px);margin-top: 10px;margin-left: 0px;" @click="opcus" type="primary">耗材领用</el-button>
    </div>

    <div class="btnbox" v-if="device.production_status === 'on' && device.handle_staff === swstaff(staffsid)">
      <el-button class="btn" type="danger" @click="opend">结束</el-button>
    </div>
    <el-dialog title="插单" :visible.sync="insertVisible" width="80%">
      <el-form :model="insertData" style="padding: 0 5px;">
        <el-form-item label="物料名称:" label-width="80px">
          <el-input style="width:calc(100%);" placeholder="请输入物料名称" v-model="insertData.material_name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="数量:" label-width="80px">
          <el-input style="width:calc(100%);" type="number" placeholder="请输入完成数量" v-model="insertData.num" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item label="备注:" label-width="80px">
          <el-input style="width:calc(100%);" placeholder="请输入备注" v-model="insertData.remark" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="insertVisible = false">取 消</el-button>
        <el-button type="primary" @click="insert">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="结束当前任务" :visible.sync="endVisible" width="80%">
      <el-form :model="endData" style="padding: 0 5px;">

        <el-form-item label="完成数量:" label-width="80px">
          <el-input style="width:calc(100%);" type="number" placeholder="请输入完成数量" v-model="endData.complete_num" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="结束时间:" label-width="80px">
          <el-date-picker style="width:calc(100%);" v-model="endData.end_time" type="datetime" placeholder="选择日期时间">
          </el-date-picker>
          <!-- <input ref="datainput" style="width100%;height: 30px;line-height: 30px;padding: 0 10px;box-sizing: border-box;" v-model="endData.end_time" type="datetime-local" placeholder="选择日期时间"> -->
        </el-form-item>
        <el-form-item label="备注:" label-width="80px">
          <el-input style="width:calc(100%);" placeholder="请输入备注" v-model="endData.remark" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="endVisible = false">取 消</el-button>
        <el-button type="primary" @click="end">确 定</el-button>
      </span>
    </el-dialog>
  </div>

</template>

<script>

import axios from 'axios';
import { Message } from 'element-ui';
import Cookies from 'js-cookie';
export default {
  data () {
    return {
      iot_id: '',
      activeNames: ['1'],
      devices: [],
      schedules: [],
      staffsid: '',
      company: {},
      staffs: [],
      value: '',
      staff: {
        name: '',
        code: ''
      },
      ismake: true,
      device: {},
      schedule_id: '',
      startTime: "",
      date: new Date().valueOf(),
      timer: "",
      endVisible: false,
      endData: {
        end_time: '',
        complete_num: '',
        remark: ''
      },
      insertVisible: false,
      insertData: {
        material_name: "",
        num: '',
        remark: ''
      }
    };
  },
  methods: {
    // 切换
    change_iot_id (value) {
      console.log(value);
      console.log(this.iot_id);
      this.schedule_id = '';
      this.gethandle(this.iot_id);
    },
    //插单
    opInsert () {
      this.insertVisible = true;
      this.insertData = {
        material_name: "",
        num: '',
        remark: ''
      };
    },
    opcus () {
      console.log(this.company.id);
      this.$router.push('Claim?company_id=' + this.company.id);
    },
    insert () {
      let data = this.insertData;
      data.staff_id = this.staffsid;
      data.iot_id = this.iot_id;
      data.num = data.num ? Number(data.num) : '';
      data.type = 2;
      if (!data.iot_id) {
        this.$message.error({
          message: '机器不能为空'
        });
        return;
      }
      if (!data.staff_id) {
        this.$message.error({
          message: '员工不能为空'
        });
        return;
      }
      console.log(data);
      if (data.num === '' || data.num === 0) {
        this.$message.error({
          message: '数量不能为空或者0'
        });
        return;
      }
      if (data.material_name === '') {
        this.$message.error({
          message: '名称不能为空'
        });
        return;
      }
      axios({
        method: 'post',
        url: process.env.VUE_APP_APIHOST + '/staff/handle',
        data
      }).then(res => {
        console.log(res);
        if (res.data.code !== 200) {
          this.$message.error({
            message: res.data.message
          });
          return;
        }
        if (res.data.code === 200) {
          this.$message({
            message: '操作成功',
            type: 'success'
          });

          this.startTime = this.dayjs(new Date).format('YYYY-MM-DD HH:mm:ss');
          this.gethandle(this.iot_id);
          this.insertVisible = false;
        }

      }).catch(res => {
        Message({
          message: res.msg || res.message || 'Error',
          type: 'error',
          duration: 5 * 1000
        });
      });
    },
    // 开始结束
    opend () {
      this.endVisible = true;
      this.endData = {
        end_time: '',
        complete_num: '',
        remark: ''
      };
      let lidata = this.dayjs(new Date).format('YYYY-MM-DD HH:mm:ss');
      var u = navigator.userAgent;
      var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isiOS) {
        lidata = this.dayjs(new Date).format('YYYY/MM/DD HH:mm:ss');
      } else {
        // 安卓系统
        lidata = this.dayjs(new Date).format('YYYY-MM-DD HH:mm:ss');
      }
      this.endData.end_time = lidata;


    },
    end () {
      let data = this.endData;
      if (data.complete_num === "") {
        this.$message.error({
          message: '请输入完成数量'

        });
        return;
      }
      if (!data.end_time) {
        this.$message.error({
          message: '请选择完成时间'
        });
        return;
      }
      data.log_id = this.device.log_id;
      data.iot_id = this.iot_id;
      data.complete_num = Number(data.complete_num);
      console.log(data);
      axios({
        method: 'put',
        url: process.env.VUE_APP_APIHOST + '/staff/handle/' + data.log_id,
        data
      }).then(res => {
        console.log(res);
        if (res.data.code !== 200) {
          this.$message.error({
            message: res.data.message
          });
          return;
        }
        this.$message({
          message: '操作成功',
          type: 'success'
        });

        this.gethandle(this.iot_id);
        this.endVisible = false;
      }).catch(res => {
        Message({
          message: res.msg || res.message || 'Error',
          type: 'error',
          duration: 5 * 1000
        });
      });

    },
    on () {
      let data = {};
      data.staff_id = this.staffsid;
      data.iot_id = this.iot_id;
      data.type = 1;
      data.schedule_id = this.schedule_id;
      data.material_name = this.device.curr_material_name;
      if (!data.staff_id) {
        this.$message.error({
          message: ' 员工不能为空'
        });
        return;
      }
      if (!data.iot_id) {
        this.$message.error({
          message: '机器不能为空'
        });
        return;
      }
      if (!data.schedule_id) {
        this.$message.error({
          message: '请先选择生产任务'
        });
        return;
      }
      axios({
        method: 'post',
        url: process.env.VUE_APP_APIHOST + '/staff/handle',
        data
      }).then(res => {
        console.log(res);
        if (res.data.code !== 200) {
          this.$message.error({
            message: res.data.message
          });
          return;
        }
        if (res.data.code === 200) {
          this.$message({
            message: '操作成功',
            type: 'success'
          });

          this.startTime = this.dayjs(new Date).format('YYYY-MM-DD HH:mm:ss');
          this.gethandle(this.iot_id);
        }

      }).catch(res => {
        Message({
          message: res.msg || res.message || 'Error',
          type: 'error',
          duration: 5 * 1000
        });
      });
    },
    xz (row) {
      console.log(row);
      this.device.curr_material_name = row.material_name;
      this.device.work_procedure = row.work_procedure;
      this.device.num = row.num;
      this.device.complete_num = row.complete_num;
      this.device.clamp_way = row.clamp_way;
      this.device.remark = row.remark;
      this.device.handle_staff = this.swstaff();
      this.schedule_id = row.id;

    },
    disposeDate (itme) {
      let newitme = '';
      var u = navigator.userAgent;
      var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isiOS) {
        newitme = this.date - new Date(itme.replace(/-/g, '/')).getTime();
      } else {
        // 安卓系统
        newitme = this.date - new Date(itme).getTime();
      }
      return this.dateConvert(newitme);
    },
    dateConvert (value) {
      let hour,
        min,
        sec,
        createDate = value / 1000;
      hour = parseInt(createDate / 60 / 60);
      min = parseInt((createDate - hour * 3600) / 60);
      sec = parseInt(createDate - (hour * 3600 + min * 60));
      if (hour > 0) {
        if (min > 0) {
          if (sec > 0) {
            createDate = hour + "时" + min + "分" + sec + "秒";
          } else {
            createDate = hour + "时" + min + "分";
          }
        } else {
          if (sec > 0) {
            createDate = hour + "时" + sec + "秒";
          } else {
            createDate = hour + "时";
          }
        }
      } else if (min > 0) {
        createDate = sec > 0 ? min + "分" + sec + "秒" : min + "分";
      } else if (sec > 0) {
        createDate = sec + "秒";
      }
      return createDate;
    },
    // 验证员工信息
    swstaff () {
      let text = '';
      this.staffs.forEach(item => {
        if (item.id === this.staffsid) {
          text = item.name;
        }
      });
      return text;
    },
    isstaff () {

      this.staffs.forEach(item => {
        if (item.code === this.staff.code) {
          if (item.name === this.staff.name) {
            this.ismake = false;
            this.staffsid = item.id;
            Cookies.set('staff', item);
            Cookies.set('company', this.company.name);
          }
        }
      });
      if (this.ismake) {
        this.$message.error({
          message: '员工信息错误，无法访问'
        });
      }
    },
    gethandle (id) {

      axios({
        method: 'get',
        url: process.env.VUE_APP_APIHOST + '/staff/handle?iot_id=' + id
      }).then(res => {
        console.log(res);
        this.devices = res.data.data.devices;
        this.staffs = res.data.data.staffs;
        this.schedules = res.data.data.schedules;
        this.device = res.data.data.device;
        this.company = res.data.data.company;
        this.startTime = res.data.data.device.start_time;
        if (Cookies.get('staff') && this.company.name === Cookies.get('company')) {
          let staffdata = JSON.parse(Cookies.get('staff'));
          console.log(staffdata);
          this.staffsid = staffdata.id;
          this.ismake = false;
        }
      }).catch(res => {
        Message({
          message: res.msg || res.message || 'Error',
          type: 'error',
          duration: 5 * 1000
        });
      });
    }
  },
  mounted () {



    this.iot_id = this.$route.query.iot_id;
    if (this.iot_id) {
      this.gethandle(this.iot_id);
    }
    const _this = this;
    this.timer = setInterval(() => {
      _this.date = new Date(); // 修改数据date
    }, 1000);

  },
  beforeDestroy () {
    if (this.timer) {
      clearInterval(this.timer); // 在Vue实例销毁前，清除我们的定时器
    }
  }

};
</script>
<style  >
.work {
  background: white;
  width: 100%;
  min-height: 100%;
}
.work .title2 {
  background: #28a95c;
  color: white;
  text-align: center;
  height: 40px;
  line-height: 40px;
  font-size: 18px;
  font-weight: bold;
}
.work .sele {
  width: 50%;
  float: left;
  padding: 5px 5px;
  box-sizing: border-box;

  line-height: 32px;
  background-color: #b9b6b6;
}
.work .sele .el-select {
  width: calc(100% - 60px);
}
.work .sele span {
  display: inline-block;
  width: 60px;
  font-size: 14px;
}
.work .tablebox {
  width: 100%;
  padding: 10px 10px;
  box-sizing: border-box;
}
.work .collapsebox {
  width: 100%;
  padding: 0px 10px;
  box-sizing: border-box;
}
.work .dataP .num {
  display: inline-block;
  width: 33.3%;
}
.work .collapsetitle {
  font-weight: bold;
  font-size: 16px;
}
.work .btnbox {
  text-align: center;
  margin-top: 30px;
}
.work .btnbox .btn {
  width: 40%;
}
.work .make {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: black;
  z-index: 999;
  text-align: center;
}
.work .make .input {
  width: 80%;
  position: absolute;
  top: 40%;
  left: 10%;
}
.el-message {
  width: 80% !important;
  min-width: 300px !important;
  box-sizing: border-box !important;
}
</style> 
<style lang="scss" scoped>
.login {
  display: flex;
  width: 100%;
  height: 100vh;
  min-height: 500px;
  overflow: auto;
  background-image: url('../../assets/images/bg-smooth.jpg');
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
  align-items: center;
  .form {
    flex: none;
    width: 320px;
    padding: 60px;
    margin: 0 auto;
    border-radius: 4px;
    background-color: rgba($color: #ffffff, $alpha: 0.2);
    .title {
      font-weight: 300;
      margin-top: 0;
      margin-bottom: 60px;
      text-align: center;
      color: #ffffff;
    }
    .submit {
      margin-top: 30px;
      width: 100%;
    }
  }
}
.footerLogin {
  position: fixed;
  bottom: 50px;
  width: 100%;
  color: #ffffff;
  text-align: center;
  color: #808695;
  a {
    color: #808695;
  }
}
@media screen and (max-width: 750px) {
  .login {
    display: flex;
    width: 100%;
    height: 100vh;
    min-height: 500px;
    overflow: auto;
    background-image: url('../../assets/images/bg-smooth.jpg');
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
    background-size: cover;
    align-items: center;
    .form {
      flex: none;
      width: 80%;
      box-sizing: content-box;
      padding: 10px;
      margin: 0 auto;
      border-radius: 4px;
      background-color: rgba($color: #ffffff, $alpha: 0.2);
      .title {
        font-weight: 300;
        margin-top: 0;
        margin-bottom: 30px;
        text-align: center;
        color: #ffffff;
        font-size: 24px;
        font-weight: bold;
      }
      .submit {
        margin-top: 10px;
        width: 100%;
      }
    }
  }
}
</style>